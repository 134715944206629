import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getNextQuestionPageReference } from '@monorepo/shared/lib/utils';
import { AcceptanceQuestion, PremiumQuestion } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { selectPlatformState, selectTheme } from '@common/store';
import { paths } from '@common/utils';
import { useGetConsumerAcceptanceQuestionsQuery } from '@consumer/store';
import {
	selectCartCalculationTypes,
	useGetAcceptanceQuestionsQuery,
	useGetCartQuery,
	useGetPremiumQuestionsQuery,
} from '@funnel/store';

type Props = {
	isPremiumQuestions?: boolean;
	isAcceptanceQuestions?: boolean;
};
export const useQuestionPageManager = ({ isPremiumQuestions = false, isAcceptanceQuestions = false }: Props = {}) => {
	const {
		pathname,
		query: { slug, cartGuid },
	} = useRouter();
	const [nextPage, setNextPage] = useState<string | boolean>();
	const { isB2BFunnel } = useNavigation();
	const accQuestionsQuery = isB2BFunnel ? useGetAcceptanceQuestionsQuery : useGetConsumerAcceptanceQuestionsQuery;

	// Cart should be fetched at least once during the flow
	useGetCartQuery(isB2BFunnel && cartGuid ? (cartGuid as string) : skipToken);

	const { data: premiumQuestions = [] as PremiumQuestion[] } = useGetPremiumQuestionsQuery(
		cartGuid && isB2BFunnel && isPremiumQuestions ? (cartGuid as string) : skipToken
	);

	const { data: acceptanceQuestions = [] as AcceptanceQuestion[] } = accQuestionsQuery(
		cartGuid && isAcceptanceQuestions ? (cartGuid as string) : skipToken
	);

	const theme = useSelector(selectTheme);
	const activePremiumQuestion = premiumQuestions?.find(({ reference }) => reference === slug);
	const activeAcceptanceQuestion = acceptanceQuestions?.find(({ reference }) => reference === slug);

	const { isPortal } = useSelector(selectPlatformState);
	const calculationTypes = useSelector(selectCartCalculationTypes);

	// Handle the page references for the premium questions flow
	useEffect(() => {
		if (activePremiumQuestion && premiumQuestions) {
			const nextPageRef = getNextQuestionPageReference(activePremiumQuestion, premiumQuestions);
			const nextPage = isPortal
				? nextPageRef
					? paths.portal.insurances.new.profile.dynamic(`${nextPageRef}`)
					: paths.portal.insurances.new.compose.root
				: nextPageRef
					? paths.funnel.profile.dynamic(`${nextPageRef}`)
					: calculationTypes?.bundles
						? paths.funnel.compose.bundles
						: paths.funnel.compose.root;

			setNextPage(nextPage);
		} else if (slug === 'activiteiten' || pathname.includes('activiteiten')) {
			setNextPage(paths.funnel.profile.root);
		}
	}, [activePremiumQuestion, premiumQuestions, theme, slug, isPortal, cartGuid, calculationTypes, pathname]);

	// Handle the page references for the acceptance questions flow
	useEffect(() => {
		if (slug === 'cancel-current-insurance' && isAcceptanceQuestions && !acceptanceQuestions.length) {
			return isPortal
				? setNextPage(paths.portal.insurances.new.summary)
				: isB2BFunnel
					? setNextPage(paths.funnel.finalize.contact_details)
					: setNextPage(paths.consumerFunnel.finalize.contactDetails);
		}

		if (activeAcceptanceQuestion && acceptanceQuestions) {
			const nextPageRef = getNextQuestionPageReference(activeAcceptanceQuestion, acceptanceQuestions);

			setNextPage(
				isPortal
					? nextPageRef
						? paths.portal.insurances.new.preconditions.dynamic(`${nextPageRef}`)
						: paths.portal.insurances.new.summary
					: isB2BFunnel
						? nextPageRef
							? paths.funnel.preconditions.dynamic(`${nextPageRef}`)
							: paths.funnel.finalize.contact_details
						: nextPageRef
							? paths.consumerFunnel.preconditions.dynamic(`${nextPageRef}`)
							: paths.consumerFunnel.finalize.contactDetails
			);
		} else if (slug === 'current-insurance' && acceptanceQuestions.length === 0) {
			setNextPage(paths.funnel.finalize.contact_details);
		}

		switch (slug) {
			case 'cancel-current-insurance':
				setNextPage(
					isPortal
						? paths.portal.insurances.new.preconditions.acceptance_statement
						: isB2BFunnel
							? paths.funnel.preconditions.acceptance_statement
							: paths.consumerFunnel.preconditions.acceptance_statement
				);
				break;
			case 'historical-illnesses':
			case 'historical-claims':
				setNextPage(
					isPortal
						? paths.portal.insurances.new.preconditions.closing_statement
						: isB2BFunnel
							? paths.funnel.finalize.contact_details
							: paths.consumerFunnel.finalize.contactDetails
				);
				break;
			case 'illnesses-overview':
			case 'claims-overview':
				setNextPage(
					isPortal
						? paths.portal.insurances.new.preconditions.closing_statement
						: isB2BFunnel
							? paths.funnel.finalize.contact_details
							: paths.consumerFunnel.finalize.contactDetails
				);
				break;
			case 'closing-statement':
				setNextPage(
					isPortal
						? paths.portal.insurances.new.finalize.summary
						: isB2BFunnel
							? paths.funnel.finalize.summary
							: paths.consumerFunnel.finalize.summary
				);
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeAcceptanceQuestion, acceptanceQuestions, slug, isPortal]);

	return {
		nextPage,
	};
};
