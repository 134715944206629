import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ToasterNotification } from '@monorepo/components/DataDisplay';

import { NavigationContext } from '@common/contexts';
import {
	selectPlatformState,
	setNavBack,
	setNavForward,
	setNavigating,
	setNavigationDirection,
	setPlatformState,
} from '@common/store';
import { paths } from '@common/utils';
import { useGetConsumerCartQuery } from '@consumer/store';
import { useProducts } from '@funnel/hooks';
import { selectCartIsExpired, selectIsCartConverted, useGetCartQuery } from '@funnel/store';

let globalIsBackAction = false;
const protectedStartUrls = ['/portaal', '/superadmin'];
const toRequestContactPageUrls = ['/samenstellen'];
const privateFunnelPaths = ['/particulier'];

export const NavigationProvider: FC<PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation();
	const { events, pathname: currentPathname, asPath, back, query, push, replace } = useRouter();
	const cartGuid = query?.cartGuid as string,
		insuranceGuid = query?.insuranceGuid as string;

	const isB2CFunnel = useMemo(() => privateFunnelPaths.some((path) => asPath?.startsWith(path)), [asPath]);

	const useCartQuery = isB2CFunnel ? useGetConsumerCartQuery : useGetCartQuery;

	// Make sure we always have the cart and calculations fetched
	useCartQuery(cartGuid ? cartGuid : skipToken);
	const { calculations, isLoading, isCalculated } = useProducts(cartGuid);

	const dispatch = useDispatch();
	const { isPortal } = useSelector(selectPlatformState);
	const isCartConverted = useSelector(selectIsCartConverted);
	const isCartExpired = useSelector(selectCartIsExpired);

	const goBack = () => {
		globalIsBackAction = true;
		back();
	};

	const handleConvertedCart = async () => {
		let pathname: string = '';

		if (isPortal) {
			pathname = paths.portal.insurances.new.conclusion.root;
		} else if (isB2CFunnel) {
			pathname = insuranceGuid
				? paths.consumerFunnel.conclusion.approved_after_review
				: paths.consumerFunnel.conclusion.root;
		} else {
			pathname = insuranceGuid ? paths.funnel.conclusion.approved_after_review : paths.funnel.conclusion.root;
		}

		// Do nothing if we're already on the correct page
		if (currentPathname === pathname) {
			return;
		}

		const query = insuranceGuid ? { insuranceGuid } : { cartGuid };

		await push({
			pathname,
			query,
		});

		toast.custom(
			({ visible }) => (
				<ToasterNotification
					title={t('toasts.convertedCart.title')}
					visible={visible}
					message={t('toasts.convertedCart.message')}
					status='success'
				/>
			),
			{
				ariaProps: {
					role: 'alert',
					'aria-live': 'assertive',
				},
				id: 'converted-cart-toast',
			}
		);
	};

	const handleRequestContact = async () => {
		await replace(
			{
				pathname: isPortal
					? paths.portal.insurances.new.finalize.request_contact
					: isB2CFunnel
						? paths.consumerFunnel.finalize.request_contact
						: paths.funnel.finalize.request_contact,
				query: { cartGuid },
			},
			undefined,
			{ shallow: true }
		);
	};

	useEffect(() => {
		if (calculations) {
			if (
				toRequestContactPageUrls.some((refererItem) => asPath?.includes(refererItem)) &&
				calculations &&
				!isLoading &&
				isCalculated &&
				calculations?.length === 0 &&
				!isB2CFunnel
			) {
				handleRequestContact();
			}

			if (isCartConverted) {
				handleConvertedCart();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calculations, isCartConverted, isCartExpired, isLoading, isCalculated]);

	useEffect(() => {
		if (isCartExpired) {
			push({
				// TODO: Add correct consumer path
				pathname: isB2CFunnel ? paths.consumerFunnel.root('motorverzekering') : paths.funnel.root,
				query: { expiredCart: true },
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCartExpired]);

	useEffect(() => {
		/**
		 * Sets global platform state (whether the user is on a protected page or not)
		 * This is used to define if we have to use public or protected API endpoints
		 */
		if (asPath) {
			const isProtected = protectedStartUrls.some((url) => asPath.startsWith(url));
			dispatch(setPlatformState(isProtected));
		}

		const handleStart = () => {
			const direction = globalIsBackAction ? 'back' : 'forward';

			if (direction === 'back') {
				dispatch(setNavBack());
			} else {
				dispatch(setNavForward());
			}
		};

		const handleComplete = () => {
			dispatch(setNavigating(false));
			dispatch(setNavigationDirection(null));
			globalIsBackAction = false;
		};

		events.on('routeChangeStart', handleStart);
		events.on('routeChangeComplete', handleComplete);
		events.on('routeChangeError', handleComplete);

		return () => {
			events.off('routeChangeStart', handleStart);
			events.off('routeChangeComplete', handleComplete);
			events.off('routeChangeError', handleComplete);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [events, dispatch, asPath]);

	const isChildRoute = useMemo(() => Object.keys(query).length > 0, [query]);

	return (
		<NavigationContext.Provider value={{ goBack, isChildRoute, isB2CFunnel, isB2BFunnel: !isB2CFunnel }}>
			{children}
		</NavigationContext.Provider>
	);
};
